import { render, staticRenderFns } from "./member-make-administrator.html?vue&type=template&id=3be971a1&scoped=true&"
import script from "./member-make-administrator.js?vue&type=script&lang=js&"
export * from "./member-make-administrator.js?vue&type=script&lang=js&"
import style0 from "./member-make-administrator.scss?vue&type=style&index=0&id=3be971a1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be971a1",
  null
  
)

export default component.exports