import { render, staticRenderFns } from "./ngo-workspace.html?vue&type=template&id=f16a2c28&scoped=true&"
import script from "./ngo-workspace.js?vue&type=script&lang=js&"
export * from "./ngo-workspace.js?vue&type=script&lang=js&"
import style0 from "./ngo-workspace.scss?vue&type=style&index=0&id=f16a2c28&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f16a2c28",
  null
  
)

export default component.exports